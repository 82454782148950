<script>
  import Recipe from "./Recipe.svelte";
  import { recipies } from "./recipies-store.js";
  recipies.useLocalStorage();

  function uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  function deleteRecipe(obj) {
    const { id } = obj.detail;
    console.log("in deleteRecipe with id", id, obj);
    recipies.update(old => {
      const safe = [];
      for (const rec of old) {
        if (rec.id === id) {
          continue;
        }
        safe.push(rec);
      }
      return safe;
    });
  }

  function newRecipe() {
    const newRec = {
      ingredients: [],
      steps: [],
      name: "New Title",
      section: "Other",
      author: "",
      editable: true,
      id: uuidv4()
    };
    recipies.update(old => [newRec, ...old]);
  }

  function saveAll() {
    const content =
      "data:text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify($recipies));

    const node = document.createElement("a");
    node.setAttribute("href", content);
    node.setAttribute("download", "recipies.json");
    document.body.appendChild(node);
    node.click();
    node.remove();
  }

  function validateRecipies(recs) {
    const alertMsg = "Could not parse file, check console for more info";
    if (!Array.isArray(recs)) {
      console.error("Expected a javascript array, found", recs);
      alert(alertMsg);
      return [];
    }
    if (recs.length === 0) {
      console.error("Found no recipies!");
      alert(alertMsg);
      return [];
    }
    for (const rec of recs) {
      if (!(typeof rec === "object" && rec !== null)) {
        console.error("Recs contained non object: ", rec);
        alert(alertMsg);
        return;
      }
      // check for properties
      const requiredProps = ["steps", "ingredients", "name"];
      const otherProps = {
        author: "Source",
        editable: false,
        section: "Other"
      };

      for (const req of requiredProps) {
        if (!rec.hasOwnProperty(req)) {
          console.error(
            "Expected recipie to have field",
            req,
            " but didn't find it in ",
            rec
          );
          alert(alertMsg);
          return;
        }
      }
      for (const other of Object.keys(otherProps)) {
        if (!rec.hasOwnProperty(other)) {
          rec[other] = otherProps[other];
        }
      }
    }
    return recs;
  }

  async function fileUpload() {
    const el = document.getElementById("load-file");
    if (el.files.length === 0) {
      return;
    } else if (el.files.length === 1) {
      let content = await el.files[0].text();
      try {
        const recs = JSON.parse(content);
        const validated = validateRecipies(recs);
        if (validated && validated.length > 0) {
          recipies.update(old => validated);
        }
        console.log("Got theese recipies:", recs);
      } catch {
        alert("Failed to parse the file. Are you sure it is correct??");
      } finally {
        el.value = "";
      }
    }
  }

  function clickFileUpload() {
    document.getElementById("load-file").click();
  }
</script>

<style>
  main {
    margin: 50px auto;
  }

  .newrec {
    width: 6in;
    margin: 0.25in auto;
  }

  #load-file {
    display: none;
  }
</style>

<main>
  <div class="noprint newrec">
    <button on:click={newRecipe}>Add new recipe!</button>
    <button on:click={saveAll}>Save</button>
    <button on:click={clickFileUpload}>Upload file</button>
    <input on:change={fileUpload} id="load-file" type="file" />
    <button on:click={() => window.print()}>Print!</button>
  </div>
  {#each $recipies as rec}
    <Recipe
      bind:ingredients={rec.ingredients}
      bind:steps={rec.steps}
      bind:name={rec.name}
      bind:section={rec.section}
      bind:author={rec.author}
      bind:editable={rec.editable}
      bind:id={rec.id}
      on:delete={deleteRecipe} />
  {/each}
</main>
