<script>
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  // props
  export let ingredients = [];
  export let steps = [];
  export let name = "";
  export let section = "";
  export let author = "";
  export let editable = false;
  export let id;

  // state
  let sections = {
    "Main Course": "mains",
    Sweets: "sweets",
    Sides: "sides",
    Other: ""
  };
  let sectionNames = Object.keys(sections);

  // callbacks
  function toggleEdit() {
    editable = !editable;
  }

  const replacements = {
    "1/2": "&frac12;",
    "1/3": "&frac13;",
    "2/3": "&frac23;",
    "1/4": "&frac14;",
    "3/4": "&frac34;",
    "1/5": "&frac15;",
    "2/5": "&frac25;",
    "3/5": "&frac35;",
    "4/5": "&frac45;",
    "1/6": "&frac16;",
    "5/6": "&frac56;",
    "1/8": "&frac18;",
    "3/8": "&frac38;",
    "5/8": "&frac58;",
    "7/8": "&frac78;",
    // cup
    cups: "c",
    Cups: "c",
    cup: "c",
    Cup: "c",
    // teaspoon
    Tsp: "tsp",
    teaspoon: "tsp",
    "tea spoon": "tsp",
    Teaspoon: "tsp",
    TeaSpoon: "tsp",
    // tablespoon
    tbsp: "Tbsp",
    tablespoon: "Tbsp",
    "table spoon": "Tbsp",
    Tablespoon: "Tbsp",
    TableSpoon: "Tbsp"
  };

  function replaceStuff(s) {
    let out = (" " + s).slice(1);
    for (const have of Object.keys(replacements)) {
      out = out.replace(have, replacements[have]);
    }
    return out;
  }

  function onSave() {
    toggleEdit();
    // clean steps
    let newSteps = [];
    for (const step of steps) {
      if (step.trim().length !== 0) {
        newSteps.push(step.trim());
      }
    }
    steps = [...newSteps];

    // clean ingredients
    let newItems = [];
    for (const item of ingredients) {
      if (item.qty.trim().length !== 0 || item.name.trim().length !== 0) {
        newItems.push({
          qty: replaceStuff(item.qty.trim()),
          name: item.name.trim()
        });
      }
    }
    ingredients = [...newItems];

    // clean author
    author = author.trim();
  }

  function addStep() {
    steps = [...steps, ""];
  }

  function addItem() {
    ingredients = [...ingredients, {}];
  }

  function handleKeypress(e, getItems, enterAction) {
    if (e && e.which && e.which === 13) {
      e.preventDefault();
      const rows = getItems();
      if (e.target === rows[rows.length - 1]) enterAction();
      return;
    }
  }

  function handleDelete(e, action) {
    if (e && e.which && e.which === 8) {
      if (e.target.textContent.length === 0) {
        e.preventDefault();
        action(e);
      }
    }
  }

  function handleItemKeypress(e) {
    handleKeypress(
      e,
      () => document.getElementsByClassName("ingredient-name"),
      addItem
    );
  }

  function handleEnterSteps(e) {
    handleKeypress(
      e,
      () => document.getElementsByClassName("recipe-step"),
      addStep
    );
  }

  function focusMe(node) {
    node.focus();
  }
</script>

<style>
  /* Recipie card */
  div.recipe-card {
    width: 6in;
    height: 4in;
    background-color: #fffffa;
    padding: 0;
    margin: 0 auto 0.5in auto;
    border: 0.1in solid #32ffa4;
    box-shadow: 0 0 5px #777777;
    /* border */
  }

  table.ingredient-list tr:nth-child(even) {
    background-color: #e3fff2;
  }

  div.recipe-card.sweets {
    border-color: #ff328d;
  }

  div.recipe-card.sweets tr:nth-child(even) {
    background-color: #ffe3ef;
  }

  div.recipe-card.mains {
    border-color: #328dff;
  }

  div.recipe-card.mains tr:nth-child(even) {
    background-color: #e3efff;
  }

  div.recipe-card.sides {
    border-color: #a432ff;
  }

  div.recipe-card.sides tr:nth-child(even) {
    background-color: #f2e3ff;
  }

  /* printing */
  @media print {
    div.recipe-card {
      page-break-inside: avoid;
      margin: 0.75in auto 0 auto;
    }

    div.recipe-card:nth-child(even) {
      page-break-before: always;
    }

    .edit {
      display: none;
    }
  }

  div.recipe-content {
    height: 3.75in;
    width: 5.8in;
    margin: 0 0.1in;
  }

  /* Subitems */
  .recipe-header {
    width: 100%;
    height: 0.5in;
    padding-top: 5px;
  }
  .recipe-name {
    min-width: 60%;
    font-family: "Amatic SC";
    font-size: 36px;
    margin: 0;
  }

  .btn,
  .select-section {
    text-align: center;
    margin: 5px auto 0 auto;
    display: block;
    font-size: 80%;
    padding: 0.5px 3px;
  }

  .edit *[contenteditable="true"] {
    font-style: italic;
    border: 0.1px solid black;
  }

  h2 {
    padding: 5px 0;
    margin: 0;
  }

  .ingredients {
    width: 42.5%;
    float: left;
    font-size: 9pt;
  }

  table.ingredient-list {
    width: 100%;
  }

  .instructions {
    width: 55%;
    float: right;
    font-size: 9pt;
  }

  .ingredients > h2 {
    font-style: italic;
  }

  .instruction-list {
    margin: 0;
  }

  ol.instruction-list li {
    padding-left: 7.5px;
  }

  .author {
    height: 0.25in;
    padding: 0 0.1in 0;
    float: right;
    min-width: 1in;
  }
</style>

<div class="recipe-card {sections[section]} {editable ? 'edit' : ''}">
  <div class="recipe-content">
    <div class="recipe-header">
      {#if editable}
        <h1
          class="left recipe-name"
          contenteditable="true"
          bind:textContent={name}>
          {name}
        </h1>
        <button on:click={onSave} class="noprint right btn">Save</button>
        <select
          style="margin: 5px 8px;"
          class="right select-section"
          name="section"
          bind:value={section}>
          {#each Object.keys(sections) as sec}
            <option value={sec}>{sec}</option>
          {/each}
        </select>
        <i
          class="noprint right fa fa-trash fa-2x"
          on:click={() => {
            dispatch('delete', { id });
          }} />
      {:else}
        <h1 class="left recipe-name">{name}</h1>
        <i class="noprint right fa fa-edit fa-2x" on:click={toggleEdit} />
      {/if}
    </div>
    <div class="ingredients">
      <h2>Ingredients</h2>
      <table class="ingredient-list">
        {#each ingredients as { qty, name }, i}
          <tr>
            {#if editable}
              <td
                contenteditable="true"
                bind:innerHTML={qty}
                use:focusMe
                on:keypress={e => handleKeypress(e, () => [], () => {})}
                on:keyup={e => handleDelete(e, e => {
                    e.target.parentElement.remove();
                    const rows = document.getElementsByClassName('ingredient-name');
                    rows[rows.length - 1].focus();
                  })} />
              <td
                class="ingredient-name"
                contenteditable="true"
                bind:textContent={name}
                on:keypress={handleItemKeypress} />
            {:else}
              <td>
                {@html qty}
              </td>
              <td>{name}</td>
            {/if}
          </tr>
        {/each}
      </table>
      {#if editable}
        <button class="btn" on:click={addItem}>add row</button>
      {/if}
    </div>
    <div class="instructions">
      <h2>Instructions</h2>
      <ol class="instruction-list">
        {#each steps as step}
          {#if editable}
            <li
              class="recipe-step"
              contenteditable="true"
              bind:textContent={step}
              use:focusMe
              on:keyup={e => handleDelete(e, e => {
                  e.target.remove();
                  const steps = document.getElementsByClassName('recipe-step');
                  steps[steps.length - 1].focus();
                })}
              on:keypress={handleEnterSteps} />
          {:else}
            <li>{step}</li>
          {/if}
        {/each}
      </ol>
      {#if editable}
        <button class="btn" on:click={addStep}>Add step</button>
      {/if}
    </div>
  </div>
</div>
