import { writable, get } from "svelte/store";

const createWritableStore = (key, startValue) => {
  const original = writable(startValue);
  const { set, subscribe } = original;
  return {
    subscribe,
    set,
    update: fn => {
      set(fn(get(original)));
    },
    useLocalStorage: () => {
      const json = localStorage.getItem(key);
      if (json) {
        set(JSON.parse(json));
      }
      subscribe(current => {
        localStorage.setItem(key, JSON.stringify(current));
      });
    },
  };
};

export const recipies = createWritableStore("recipies", [
  {
    steps: [
      "Pre-heat oven to 350",
      "Cream butter, eggs, vanilla, and sugars in large mixing bowl",
      "Add flour, salt, chocolate chips, and baking soda. Mix until fully combined",
      "Bake for 6-8 minutes",
    ],
    ingredients: [
      {
        qty: "1 c",
        name: "dark brown sugar",
      },
      {
        qty: "1 c",
        name: "butter",
      },
      {
        qty: "1 &frac12",
        name: "eggs",
      },
      {
        qty: "&frac34; tsp",
        name: "baking soda",
      },
      {
        qty: "&frac34; c",
        name: "sugar",
      },
      {
        qty: "1 Tbsp",
        name: "vanilla",
      },
      {
        qty: "3 c",
        name: "flour",
      },
      {
        qty: "&frac34; tsp",
        name: "salt",
      },
      {
        qty: "1 &frac12; c",
        name: "chocolate chips",
      },
    ],
    name: "Chocolate Chip Cookies",
    section: "Sweets",
    author: "Gaylene Merchant",
    id: "d3475c59-151c-44ba-8602-ba8407049832",
  },
]);
